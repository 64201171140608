import { DatePicker } from "antd";
import React, { useEffect, useState, useRef } from "react";
import api1 from "../..";
import Room from "../../components/Room";
import Hotel from "../../components/Hotel";
import "./home.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-widgets/styles.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { apiConfig }  from "../../util/config";

import moment from "moment";
import "antd/dist/antd.css";

const { RangePicker } = DatePicker;

const Home = () => {
  let navigate = useNavigate();

  // update data so that it is equal to response from endpoint
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [dateStatus, setDateStatus] = useState();
  const dateRef = useRef(null);

  // these are moment objects
  const [startdate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);

  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const [hotellocation, setHotelLocation] = useState("");
  const [propertylocations, setPropertyLocations] = useState([]);
  const [propertylocationnames, setPropertyLocationNames] = useState({});

  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [totalGuests, settotalGuests] = useState();

  const [duplicaterooms, setduplicaterooms] = useState([]);

  const [searchPressed, setsearchPressed] = useState(false);

  const data = { propertyCode: [] };

  const hotelIdMap = {
    berlin: "BER",
    london: "LND",
    munich: "MUC",
    paris: "PAR",
    vienna: "VIE",
  };
  const headers = {};
  const PROPERTIES_LISTING = process.env.REACT_APP_API_PROPERTIES_LISTING;
  
  headers[apiConfig.SUBSCRIPTION_HEADER_KEY]= apiConfig.SUBSCRIPTION_KEY;
  headers["Access-Control-Request-Method"]= "POST";
  headers["Origin"]= apiConfig.Origin;

  
  

  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // retrieve inventory
        setLoading(true);
        

        const response = await api1.post(
          PROPERTIES_LISTING,
          data,
          {
          
            headers: headers
          }
      );

        if (Array.isArray(response.data)) {
          setHotels(response.data);
        } else {
          setHotels([]);
        }
        console.log(response.data);

        // update properties list
        const locations = [];
        const locationcodenames = {};
        response.data.forEach((property) => locations.push(property.name));
        response.data.forEach((property) => locationcodenames[property.name] = property.code);

        setPropertyLocations(locations);
        setPropertyLocationNames(locationcodenames);
        setLoading(false);
      } catch (error) {
        setError(true);
        console.error(error.message);
        setLoading(false);
      }
    };

    fetchData();
    return;
  }, []);

  function searchFunction() {
    console.log(hotelIdMap);
    const hotellocationlower = hotellocation.toLowerCase().trim();
    let hotelID = propertylocationnames[hotellocation.trim()];

    console.log(hotelID);
    settotalGuests(options.children + options.adult);

    if (!moment(startdate).isValid() && !moment(enddate).isValid()) {
      setDateStatus("error");
    } else if (!hotelID) {
      alert("choose the correct location");
      console.log(hotellocation.toLowerCase());
    } else {
      setsearchPressed(true);
      setDateStatus("");


      const path = `/search/${propertylocationnames[hotellocation.trim()]}/${propertylocationnames[hotellocation.trim()]}/${start}/${end}/${
        options.children + options.adult
      }`;
      navigate(path);
    }
  }

  function onChange(date) {
    if (date) {
      setStartDate(date[0]);
      setEndDate(date[1]);

      setStart(moment(date[0]).format("YYYY-MM-DD"));
      setEnd(moment(date[1]).format("YYYY-MM-DD"));
    } else {
      setDateStatus("error");
      setStartDate(null);
      setEndDate(null);
    }

    // check to see if search has been pressed, if so, just automatically update
  }

  function changeDropdownText(text) {
    setHotelLocation(text);
  }

  const handleOption = (name, operation) => {
    setOptions((prev) => {
      return {
        ...prev,
        [name]: operation === "i" ? options[name] + 1 : options[name] - 1,
      };
    });
  };

  return (
    <div className="container">
      { propertylocations.length > 0 &&
      <div className="row justify-content-center mt-5 bordersolid">
        <div className="row justify-content-center mt-2">
          <RangePicker
            ref={dateRef}
            status={dateStatus}
            format="YYYY-MM-DD"
            onChange={onChange}
            disabledDate={(current) => {
              return moment().add(-1, "days") >= current;
            }}
          />
        </div>

        <div className="row justify-content-center mt-2">
          <div className="options">
            <div className="col-md-5 optionitems">
              <span>Adults:</span>
              <div className="optioncounter">
                <button
                  className="optioncounterbutton"
                  disabled={options.adult <= 1}
                  onClick={() => handleOption("adult", "d")}
                >
                  -
                </button>
                <span>{options.adult}</span>
                <button
                  className="optioncounterbutton"
                  onClick={() => handleOption("adult", "i")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="col-md-5 optionitems">
              <span>Children:</span>
              <div className="optioncounter">
                <button
                  className="optioncounterbutton"
                  disabled={options.children <= 0}
                  onClick={() => handleOption("children", "d")}
                >
                  -
                </button>
                <span>{options.children}</span>
                <button
                  className="optioncounterbutton"
                  onClick={() => handleOption("children", "i")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="col-md-5 optionitems">
              <span>Rooms:</span>
              <div className="optioncounter">
                <button
                  className="optioncounterbutton"
                  disabled={options.room <= 1}
                  onClick={() => handleOption("room", "d")}
                >
                  -
                </button>
                <span>{options.room}</span>
                <button
                  className="optioncounterbutton"
                  onClick={() => handleOption("room", "i")}
                >
                  +
                </button>
              </div>
            </div>

            <div className="col-md-5 optionitems">
              <span>Location:</span>
              <div className="optioncounter">
                <DropdownButton
                  variant="success"
                  className="dropdown-basic"
                  id="dropdown-basic"
                  title={hotellocation ? hotellocation : "Select a location"}
                >
                  {propertylocations.length > 0 &&
                    propertylocations.map((item) => (
                      <Dropdown.Item
                        href=""
                        onClick={(e) =>
                          changeDropdownText(e.target.textContent)
                        }
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>

        <button className="searchButton" onClick={() => searchFunction()}>
          Search
        </button>
      </div>
      }

      {
        <div className="row justify-content-center mt-5">
          {loading ? (
            <Spinner animation="grow" />
          ) : error ? (
            <h1>error</h1>
          ) : (
            hotels?.map((hotel, index) => {
              return (
                <div className="col md-9 mt-2" key={"room" + hotel.id}>
                  <Hotel
                    index={index}
                    room={hotel}
                    fromdate={start}
                    todate={end}
                    guests={options.adult + options.children}
                  />
                </div>
              );
            })
          )}
        </div>
      }
    </div>
  );
};

export default Home;
