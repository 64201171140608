import './App.css';

import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import Home from './pages/home/Home';
import Navbar from './components/Navbar';
import BookingScreen from './pages/bookingscreen/BookingScreen';
import PaymentContainer from './pages/payment/Payment';
import { RedirectContainer } from "./pages/redirect/Redirect";
import { StatusContainer } from "./pages/status/Status";
import Search from "./pages/search/Search";
import CheckIn from "./pages/checkin/CheckIn";


function App() {
  return (
    <div className='App'>
      <Navbar/>
      <HashRouter hashType="hashbang">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/book/:locationid/:hotelid/:rateplancode/:unitgroupcode/:fromdate/:todate/:guests" element={<BookingScreen />}/>
          <Route path="/payment" element={<PaymentContainer />} />
          <Route path="/status/:type" element={<StatusContainer />} />
          <Route path="/status/:type/:bookref" element={<StatusContainer />} />
          <Route path="/redirect" element={<RedirectContainer />} />
          <Route path="/search/:propertyid/:location/:fromdate/:todate/:guests" element={<Search />} />
          <Route path="/checkin" element={<CheckIn />}/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
