import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';
import { apiConfig }  from "../util/config";

export const slice = createSlice({
  name: "payment",
  initialState: {
    error: "",
    session: null,
    orderRef: null,
    paymentDataStoreRes: null,
    config: {
      storePaymentMethod: true,
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: "Credit or debit card",
          amount: {
            value: 50000, // 100€ in minor units
            currency: "GBP",
          },
        },
      },
      locale: "en_US",
      showPayButton: true,
      clientKey: "test_5HNB3JWIRVAM7PPOGRBXA4LRBELL6FAZ",
      environment: "test",

    },
  },
  reducers: {
    paymentSession: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        [state.session, state.orderRef] = res;
      }
    },
    clearPaymentSession: (state) => {
      state.error = "";
      state.session = null;
      state.orderRef = null;
    },
    paymentDataStore: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        state.paymentDataStoreRes = res;
      }
    },
    updateAmount: (state,action) => {


      // update client side amount
      state.config.paymentMethodsConfiguration.card.amount.value = action.payload;

    },
  },
});

export const { paymentSession, clearPaymentSession, paymentDataStore , updateAmount} = slice.actions;

export const initiateCheckout = (url, data) => async (dispatch) => {
  /*const response = await fetch(`/api/checkout/sessions?type=${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ amount })

  });

  dispatch(updateAmount(amount));
  console.log('yeah')

  //const response = await axios.get(`/api/payments/sessions?type=${type}`);

  dispatch(paymentSession([await response.json(), response.status]));*/
  const headers = {};
  headers["Content-Type"]= "application/json";
  headers[apiConfig.SUBSCRIPTION_HEADER_KEY]= apiConfig.SUBSCRIPTION_KEY;
  const res = await fetch(url, {
    method: "POST",
    body: data ? JSON.stringify(data) : "",
    headers:headers
  });
  dispatch(paymentSession([await res.json(), res.status]))
  return res['reference']
};

export const getPaymentDataStore = () => async (dispatch) => {
  const response = await fetch("/api/checkout/getPaymentDataStore");
  dispatch(paymentDataStore([await response.json(), response.status]));
};


export default slice.reducer;
