import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import "./status.css";


export const Message = ({ type, reason, bookref }) => {
  let msg, img;
  switch (type) {
    case "pending":
      msg = <span>Your order has been received! Payment completion pending.</span>;
      img = "success";
      break;
    case "failed":
      msg = <span>The payment was refused. Please try a different payment method or card.</span>;
      img = "failed";
      break;
    case "error":
      msg = (
        <span>
          Error! Reason: {reason || "Internal error"}, refer to&nbsp;
          <a href="https://docs.adyen.com/development-resources/response-handling">Response handling.</a>
        </span>
      );
      img = "failed";
      break;
    default:
      msg = <span>Your room has been booked, your order reference is: {bookref}.</span>;
      img = "success";
  }

  return (
    <div style={{alignItems:'center', justifyContent:'center'}}>
      <img src={require(`../../../public/images/${img}.svg`)} className="status-image" alt={img} />
      <p className="status-message">{msg}</p>
    </div>
  );
};

export const StatusContainer = () => {
  let { type, bookref } = useParams();
  let query = new URLSearchParams(useLocation().search);
  let reason = query ? query.get("reason") : "";

  console.log(bookref)

  return (
    <div className="status-container">
      <div className="status">
        <Message type={type} bookref={bookref} />
        <Link to="/" className="button">
          Return Home
        </Link>
      </div>
    </div>
  );
}
