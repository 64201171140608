export const apiConfig =  {
    "SERVER_URL": "https://api.dev.ciepocdev.com",
    "SUBSCRIPTION_HEADER_KEY": "Ocp-Apim-Subscription-Key",
    "SUBSCRIPTION_KEY": "e2edcfed732e44b4bf974710dfbbe234",
    // "PROPERTIES_LISTING": "/properties",
    // "INVENTORY_SEARCH": "/search/v1/",
    // "PAYMENT_SESSION":"/payment/v1/checkoutsession",
    // "PAYMENT_PREAUTHORIZE":"/payment/v1/preAuthorizePayment",
    // "BOOKING":"/booking/v1/",
    // "CHECKIN":"/checkin/v1/",
    "TOKEN_ENDPOINT":"https://login.microsoftonline.com/8dd0febe-fa99-45a7-be8c-2c00c4e9c9c9/oauth2/v2.0/token",
    "CLIENT_ID":"334a173f-9d65-4022-b1d2-b91ce813d710",
    "CLIENT_SECRET":"zTN8Q~uUUUMbLg8YAp7vWUF3knxNm1n4JbLsMb3z",
    "SCOPE":"api://334a173f-9d65-4022-b1d2-b91ce813d710/properties/.default",
    "Origin":"http://localhost:3000"
}
















// export const apiConfig =  {
//     "SERVER_URL": "https://api.dev.ciepocdev.com",
//     "SUBSCRIPTION_KEY": "794c18d04bb94c449e5f0a5fce7a2c7c",
//     "PROPERTIES_LISTING": "https://api.dev.ciepocdev.com/properties",
//     "INVENTORY_SEARCH": "https://cie-poc-software-apigateway.azure-api.net/v1/inventory/search",
//     "PAYMENT_SESSION":"https://cie-poc-software-apigateway.azure-api.net/v1/payment/session",
//     "PAYMENT_PREAUTHORIZE":"https://cie-poc-software-apigateway.azure-api.net/v1/payment/preauthorize",
//     "BOOKING":"https://cie-poc-software-apigateway.azure-api.net/v1/booking",
//     "CHECKIN":"https://cie-poc-software-apigateway.azure-api.net/v1/checkin"
// }
